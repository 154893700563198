<template>
  <div id="index">
    <div class="header">
      <img class="title" src="../../public/img/title-gallary.png" alt="" />
    </div>
    <div class="main-contents">
      <div class="gallary-menu ml70">
        <div class="title line-bottom">地域資源データベースギャラリー</div>
        <div>初期設定では画面が拡大されて表示されるため、お使いのWebブラウザの拡大/縮小機能で縮小して画面を調整して御覧ください</div>
        <div class="menu">
          <router-link to="/archive/map" class="item">
            <img src="/img/title-map.png" alt="" />
          </router-link>
          <router-link to="/archive/age" class="item">
            <img src="/img/title-age.png" alt="" />
          </router-link>
          <router-link to="/archive/story" class="item">
            <img src="/img/title-story.png" alt="" />
          </router-link>
        </div>
        <div class="sub-footer">
          <router-link to="/archive" class="detail">
            <img src="/img/detail-btn.png" alt="" />
          </router-link>
        </div>
      </div>
      <!-- <div class="area-map ml70">
        <div class="title line-bottom">
          <div class="dot-green"></div>
          <div class="title24 ml10">地域資源マップ</div>
        </div>
        <div class="header mt34">
          <div class="title20">地図から探す地域資源</div>
          <ul class="category-list">
            <li
              class="ml43"
              v-for="(map, index) in maps"
              :key="index"
              @click="initMap(index)"
            >
              <div :class="map.pointType"></div>
              <div class="text20 ml8">{{ map.title }}</div>
            </li>
          </ul>
        </div>
        <div style="width: 100%; height: 618px" class="mt17 mb107">
          <GMapMap
            :center="center"
            :zoom="zoom"
            style="width: 100%; height: 616px"
            :options="options"
            mapTypeId="roadmap"
          >
            <GMapCluster>
              <GMapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                :icon="{ url: iconUrl, labelOrigin: { x: 11, y: 11 } }"
                :label="{
                  text: `${index + 1}`, //ラベル文字
                  color: 'white', //ラベル文字の色
                  fontFamily: 'sans-serif', //フォント
                  fontWeight: 'bold', //フォントの太さ
                  fontSize: '14px' //フォントのサイズ
                }"
                @click="onClickMap(index)"
              />
            </GMapCluster>
          </GMapMap>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import mixin from '../mixins/mixin'
export default {
  name: 'IndexView',
  mixins: [mixin],
  data() {
    return {
      selectedMapIndex: 0,
      zoom: 7,
      maps: [],
      options: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          {
            stylers: [
              {
                hue: '#2c3e50'
              },
              {
                saturation: 250
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                lightness: 50
              },
              {
                visibility: 'simplified'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
        ]
      },
      center: { lat: 51.093048, lng: 6.84212 },
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212
          }
        } // Along list of clusters
      ]
    }
  },
  computed: {
    iconUrl() {
      if (this.currentMap) {
        return `/img/${this.currentMap.pointType}.png`
      } else {
        return `/img/point1.png`
      }
    },
    currentMap() {
      return this.maps[this.selectedMapIndex]
    }
  },
  created: function () {
    console.log('created')
  },
  mounted: async function () {
    console.log('mounted')
    console.log(this.center)
    try {
      const res = await this.getdata('/data/map.json')
      this.maps = res
      this.initMap(0)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    initMap(index) {
      this.selectedMapIndex = index
      console.log(`initMap=${index}`)
      const map = this.maps[index]
      console.log(map)
      this.center.lat = map.lat
      this.center.lng = map.lng
      this.zoom = map.zoom
      let markers = []
      map.locations.forEach((location) => {
        console.log(location)
        markers.push({ position: { lat: location.lat, lng: location.lng } })
      })
      this.markers = markers
    },
    onClickMap(index) {
      console.log(index)
      console.log(this.currentMap.locations[index].url)
    }
  }
}
</script>

<style scoped>
#index > .header {
  height: 190px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.area-map {
  width: 1240px;
}

.area-map .title {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
}

.dot-green {
  background-image: url('../../public/img/dot-green.png');
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
}

.area-map .header {
  display: flex;
  justify-content: space-between;
}

.area-map .category-list {
  width: 765px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-end; */
}

.category-list li {
  display: inline-block;
  /* margin-top: 10px;
  margin-bottom: 10px; */
  color: black;
  display: flex;
  align-items: center;
}

.point1 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point1.png');
  background-repeat: no-repeat;
}

.point2 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point2.png');
  background-repeat: no-repeat;
}

.point3 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point3.png');
  background-repeat: no-repeat;
}

.point4 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point4.png');
  background-repeat: no-repeat;
}

.point5 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point5.png');
  background-repeat: no-repeat;
}

.point6 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point6.png');
  background-repeat: no-repeat;
}

.point7 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point7.png');
  background-repeat: no-repeat;
}

.point8 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point8.png');
  background-repeat: no-repeat;
}

.point9 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point9.png');
  background-repeat: no-repeat;
}

.point10 {
  width: 25px;
  height: 31px;
  background-image: url('../../public/img/point10.png');
  background-repeat: no-repeat;
}

.mt17 {
  margin-top: 17px;
}

.mb107 {
  margin-bottom: 107px;
}

.vue-map-container {
  width: 100%;
  height: 100%;
}
</style>
