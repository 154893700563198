<template>
  <div id="archive-index">
    <div class="header">
      <img class="title" src="/img/title-gallary.png" alt="" />
    </div>
    <div class="main-contents">
      <div class="gallary-menu ml70">
        <div class="title line-bottom">地域資源データベースギャラリー</div>
      </div>

      <div class="category ml70" style="margin-top: 100px">
        <div class="header line-bottom">
          <div class="dot-pink"></div>
          <div class="title24 ml10">
            ストーリーから地域資源を見る
          </div>
        </div>
        <div
          class="story"
          v-for="(story, index) in stories"
          :key="`story_${index}`"
          v-show="story.contents.length > 0"
        >
          <div class="header">{{ story.title }}</div>
          <div class="contents">
            <button @click="onPrev(index)" :ref="`prev_${index}`">
              <img src="/img/arrow-right.png" alt="" />
            </button>
            <div
              class="list-container"
              v-dragscroll
              @dragscrollmove="onDragMove(index)"
              @dragscrollstart="onDragStart"
              @dragscrollend="onDragEnd(index)"
              :ref="`scroll_container_${index}`"
              @scroll="onScroll(index)"
            >
              <div class="list" :ref="`scroll_${index}`">
                <div
                  class="content"
                  v-for="(content, index2) in story.contents"
                  :key="`contents_${index}_${index2}`"
                  @click="onOpenDetail(index, index2)"
                >
                  <img :src="imageUrl(content.thumbnail, 'small')" alt="" />
                  <div class="title">{{ content.title }}</div>
                </div>
              </div>
            </div>
            <button @click="onNext(index)" :ref="`next_${index}`">
              <img src="/img/arrow-left.png" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div class="dummy-bottom"></div>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins/mixin'
export default {
  name: 'ArchiveIndex',
  mixins: [mixin],
  data: function () {
    return {
      timer: null,
      isLazyDragging: false,
      iframeUrl: '',
      tamaImList: [],
      stories: []
    }
  },
  async created() {
    console.log('created')
    await this.$store.dispatch('fetchStories')
    // this.tamaImList = await this.getdata('/data/map_tama_im.json')
    // console.log('this.tamaImList', this.tamaImList)
    this.stories = await this.$store.getters.stories
    console.log('this.stories', this.stories.length)
  },
  mounted() {
    console.log('+++ mounted')
    // const self = this
    // this.$store.watch(
    //   (state, getters) => getters.stories,
    //   (newValue, oldValue) => {
    //     console.log('stories changed! %s => %s', oldValue, newValue)
    //     if (newValue) {
    //       for (let index = 0; index < newValue.length; index++) {
    //         self.updateScroll(index)
    //       }
    //     }
    //   }
    // )
    // await this.$nextTick();
    // console.log(this.stories.length);
    // for (let index = 0; index < this.stories.length; index++) {
    //   this.updateScroll(index)
    // }
  },
  updated() {
    console.log(this.stories.length)
    for (let index = 0; index < this.stories.length; index++) {
      this.updateScroll(index)
    }
  },
  methods: {
    onScroll(index) {
      // console.log('onScroll')
      this.updateScroll(index)
    },
    updateScroll(index) {
      console.log(`updateScroll=${index}`)
      const ref = this.$refs[`scroll_${index}`]
      const ref2 = this.$refs[`scroll_container_${index}`]
      const prev = this.$refs[`prev_${index}`][0]
      const next = this.$refs[`next_${index}`][0]
      // console.log('prev', prev)
      // console.log('next', next)
      const cw = ref[0].clientWidth
      const sw = ref[0].scrollWidth
      const sl = ref2[0].scrollLeft
      // console.log(cw)
      // console.log(sw)
      // console.log(sl)
      if (cw >= sw) {
        // console.log('+++1')
        prev.style.visibility = 'hidden'
        next.style.visibility = 'hidden'
      } else {
        // console.log('+++2')
        if (sl == 0) {
          prev.style.visibility = 'hidden'
        } else if (sl + cw >= sw) {
          next.style.visibility = 'hidden'
        } else {
          prev.style.visibility = 'visible'
          next.style.visibility = 'visible'
        }
      }
    },
    onNext(index) {
      // const ref = this.$refs[`scroll_${index}`][0]
      const ref2 = this.$refs[`scroll_container_${index}`][0]
      // console.log('prev', prev)
      // console.log('next', next)
      // const cw = ref.clientWidth
      // const sw = ref.scrollWidth
      const sl = ref2.scrollLeft
      ref2.scrollTo({
        top: 0,
        left: sl + 260,
        behavior: 'smooth'
      })
      // const className = e.target.className
      // console.log(className)
    },
    onPrev(index) {
      // const ref = this.$refs[`scroll_${index}`][0]
      const ref2 = this.$refs[`scroll_container_${index}`][0]
      // console.log('prev', prev)
      // console.log('next', next)
      // const cw = ref.clientWidth
      // const sw = ref.scrollWidth
      const sl = ref2.scrollLeft
      ref2.scrollTo({
        top: 0,
        left: sl - 260,
        behavior: 'smooth'
      })
      // const className = e.target.className
      // console.log(className)
    },
    onDragStart() {
      console.log('onDragStart')
    },
    onDragEnd(index) {
      console.log('onDragEnd', index)
      // console.log("this.timer",this.timer);
      if (this.timer != null) {
        clearTimeout(this.timer)
        this.timer = null
      }
      console.log(this.timer)
      if (!this.timer) {
        const self = this
        this.isLazyDragging = true
        this.timer = setTimeout(() => {
          self.isLazyDragging = false
        }, 100)
      }

      // const ref = this.$refs[`scroll_${index}`];
      // const ref2 = this.$refs[`scroll_container_${index}`];
    },
    onDragMove() {
      // console.log('onDragMove')
    },
    onOpenDetail(index, index2) {
      console.log('+++onOpenDetail', index, index2)
      console.log(this.isLazyDragging)
      if (this.isLazyDragging) {
        return
      }
      this.$router.push({
        name: 'archive-story-detail',
        params: { index, index2 }
      })
    },
    onClickImageMap(index) {
      console.log(index)
      const imageMaps = this.$store.getters['imageMaps']
      console.log(imageMaps)
      const imageMap = imageMaps[index]
      const query = { keywords: imageMap.keywords }
      this.$store.dispatch('setFromForm', false)
      this.$emit('find', query, imageMap.name)
      // this.$emit('find', "")
    },
    onClickRange(index) {
      console.log(`onClickRange=${index}`)
      const ages = this.$store.getters['ages']
      console.log('ages', ages)
      console.log(ages[index])
      const age = ages[index]
      const query = { f5: `${age.range[0]}～${age.range[1]}`, f5_opt: 'range' }
      this.$store.dispatch('setFromForm', false)
      this.$emit('find', query, age.name)
      // console.log(this.$store.getters["ages"][index]);
    },
    openTamashiDA() {
      // this.iframeUrl =
      //   'https://www.youtube.com/channel/UC6W5T3tm7JSJde7v_EB50bQ'
      //       let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      // width=1000,height=600,left=100,top=100`
      //       window.open(this.iframeUrl, '_blank', params)
    }
  }
}
</script>

<style scoped>
.dummy-bottom {
  height: 50px;
}
</style>
