import { createStore } from "vuex";

console.log("store");
console.log("MAP_TAMA_IM", global.MAP_TAMA_IM);
console.log("STORIES", global.STORIES);
console.log("MAPS", global.MAPS);
console.log("AGES", global.AGES);
export default createStore({
  state: {
    stories: global.STORIES,
    ages: global.AGES,
    imageMaps: global.MAP_TAMA_IM,
    maps: global.MAPS,
    fromForm: false,
    lastForm: {
      keywords: "",
      and_or: "and",
      title: "",
      f1: "",
      f2: "",
      img: "",
    },
  },
  getters: {
    fromForm(state) {
      return state.fromForm;
    },
    stories(state) {
      return state.stories;
    },
    ages(state) {
      return state.ages;
    },
    imageMaps(state) {
      return state.imageMaps;
    },
    maps(state) {
      return state.maps;
    },
    lastForm(state) {
      return state.lastForm;
    },
  },
  mutations: {
    SET_FROM_FORM(state, payload) {
      console.log("payload", payload);
      state.fromForm = payload;
    },
    SET_AGES(state, payload) {
      console.log("payload", payload);
      state.ages = payload;
    },
    SET_STORIES(state, payload) {
      console.log("payload", payload);
      state.stories = payload;
    },
    SET_IMAGE_MAPS(state, payload) {
      console.log("payload", payload);
      state.imageMaps = payload;
    },
    SET_LAST_FORM(state, payload) {
      console.log("payload", payload);
      state.lastForm = payload;
    },
  },
  actions: {
    setLastForm({ commit }, value) {
      commit("SET_LAST_FORM", value);
    },

    setFromForm({ commit }, value) {
      commit("SET_FROM_FORM", value);
    },
    // async fetchAges({ commit }) {
    //   commit("SET_AGES", await getdata("/data/ages.json"));
    // },
    // async fetchStories({ commit }) {
    //   commit("SET_STORIES", await getdata("/data/story.json"));
    // },
    // async fetchImageMaps({ commit }) {
    //   commit("SET_IMAGE_MAPS", await getdata("/data/map_tama_im.json"));
    // },
  },
  modules: {},
});

// eslint-disable-next-line
async function getdata(url) {
  // 既定のオプションには * が付いています
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(data) // 本文のデータ型は "Content-Type" ヘッダーと一致させる必要があります
  });
  console.log("+++ getData", url);
  return response.json(); // JSON のレスポンスをネイティブの JavaScript オブジェクトに解釈
}
