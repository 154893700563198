<template>
  <div id="about" class="page">
    <div class="header">
      <img class="title" src="/img/title-gallary.png" alt="" />
    </div>
    <div v-html="external"></div>
    <!-- <div class="main-contents">
      <div class="category ml70">
        <div class="header line-bottom">
          <div class="dot-blue"></div>
          <div class="title24 ml10">このサイトについて</div>
        </div>
        <pre class="contents">
このサイトは、パルテノン多摩ミュージアムが運営し、収蔵資料および地域の資源を紹介するサイトです。※パルテノン多摩では地域全体を博物館と見立てた「地域まるごと博物館」事業をおこなっています。
        </pre>
      </div>
      <div class="category ml70 mt56">
        <div class="header line-bottom">
          <div class="dot-blue"></div>
          <div class="title24 ml10">本サイトの掲載資料について</div>
        </div>
        <div class="contents">
          <ol>
            <li>
              写真資料
              <pre>
【撮影者と写真の種類】
①旧定点撮影プロジェクトWEBギャラリー収録画像
　2007（平成19）年9月に活動を開始した「定点撮影プロジェクト」のメンバーが撮影した写真や、プロジェクト中で場所が判明した写真のうち、新旧比較写真がそろっているものが掲載されています。

②UR都市機構寄贈資料（航空斜写真・垂直写真・地上写真）
　2009（平成21）年5月に旧・住宅公団であるUR都市機構より寄贈を受けた多摩ニュータウン開発関係の写真群です。写真は1968（昭和43）年ごろから平成（に至るまでの記録写真で、それぞれの地域の工事風景や竣工・入居開始直後の様子や、各年の航空写真など数万点(詳細は調査中)に及ぶと思われるものです。

③東京都南多摩新都市開発本部関係資料（航空斜写真・垂直写真・地上写真）
　2007年にパルテノン多摩に移管された資料です。WEBギャラリーでは、1968（昭和43）年～1970（昭和45）年度にかけて撮影された写真を中心に掲載しています。「南多摩新都市開発本部」は、多摩ニュータウン開発の為に東京都が設置したものです。現在は改組されて「東京都都市整備局 市街地整備部 多摩ニュータウン事業室」となっています。

③水道事業関係写真（地上写真）
2009（平成21）年3月に多摩市水道課桜ヶ丘浄水所より寄贈されたもの。桜ヶ丘浄水所は2009（平成21）年3月まで多摩市による管理・運営を行っていましたが、同年4月から東京都が管理・運営業務を行うようになりました。これにともない、浄水所の業務上で作成された書類や図面などが各所（多摩市行政文書室・多摩市立図書館・多摩市教育委員会）へ移管となり、ここに掲載されている写真はパルテノン多摩に移管された資料の一部です。

④市民提供写真
その他、さまざまな市民により寄贈された写真など。風景、祭礼など内容は様々です。

⑤パルテノン多摩撮影写真
パルテノン多摩の過去の調査等で撮影した写真など。風景、祭礼、寺社、生物、地層など内容は様々です。</pre
              >
            </li>
            <li>
              自然資料
              <pre>
①植物標本
パルテノン多摩開館の準備段階だった1982（昭和57）年以降に市民ボランティアによって採集された植物標本や、多摩市とその周辺で活動されている植物研究家から寄贈されたコレクションなど、約1万点が当館の収蔵庫に収められています。ここでは標本情報（植物名、採集地）を公開しています。また、今後は解説を徐々に追加するとともに、当館が撮影した植物写真とリンクさせることを計画しています。

  </pre
              >
            </li>
          </ol>
        </div>
      </div>
      <div class="category ml70">
        <div class="header line-bottom">
          <div class="dot-blue"></div>
          <div class="title24 ml10">写真のご利用を希望の方へ</div>
        </div>
        <pre class="contents">
パルテノン多摩（公益財団法人多摩市文化振興財団）所蔵写真に関しては、写真資料利用の申請手続きの上ご利用いただけます。
詳細は（リンク）をご確認ください。
        </pre>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'AboutView',
  data() {
    return {
      external: ''
    }
  },
  mounted() {
    this.loadExternalHtml()
  },
  methods: {
    loadExternalHtml() {
      fetch('/data/about.html').then((res) => {
        res.text().then((html) => {
          this.external = html
        })
      })
    }
  }
}
</script>

<style>
.page {
  display: flex;
  flex-direction: column;
}
.page .category .contents {
  font-size: 30px;
  /* margin-top: 75px; */
  text-align: left;
  line-height: 51px;
  display: flex;
  flex-direction: column;

  font-family: sans-serif;
  font-weight: normal;
  white-space: pre-wrap;
}

.page pre {
  font-family: sans-serif;
  font-size: 24px;
  font-weight: normal;
  white-space: pre-wrap;
}
</style>
