<template>
  <div id="collection" class="page">
    <div class="header">
      <img class="title" src="/img/title-gallary.png" alt="" />
    </div>

    <div v-html="external"></div>
  </div>
</template>

<script>
export default {
  name: 'CollectionView',
  data() {
    return {
      external: ''
    }
  },
  mounted() {
    this.loadExternalHtml()
  },
  methods: {
    loadExternalHtml() {
      fetch('/data/collection.html').then((res) => {
        res.text().then((html) => {
          this.external = html
        })
      })
    }
  }
}
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page .category .contents {
  font-size: 30px;
  margin-top: 75px;
  text-align: left;
  line-height: 51px;
  display: flex;
  flex-direction: column;
  /* font-size: 30px; */
  font-family: sans-serif;
  font-weight: normal;
  white-space: pre-wrap;
  text-align: left;
  /* margin: 0; */
}

.contents pre {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  white-space: pre-wrap;
}
</style>
