<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<script>
console.log("+++ APP");
console.log(process.env.BASE_URL);
import DefaultLayout from './layouts/DefaultLayout.vue'
export default {
  name: 'app',
  components: { DefaultLayout },
  computed: {
    layout() {
      return (this.$route.meta.layout || this.defaultLayout) + '-layout'
    }
  },
  data() {
    console.log(process.env)
    return {
      debugTitle: process.env.VUE_APP_DISPLAY_TITLE,
      defaultLayout: 'default',
      defaultIndex: '/',
    }
  },
  mounted() {
    let route = this.$route
    this.setMeta(route)
  },
  watch: {
    // eslint-disable-next-line
    $route(route, from) {
      this.setMeta(route)
    }
  },
  methods: {
    setMeta(route) {
      if (route.meta.title) {
        let setTitle = route.meta.title
        document.title = setTitle
      }
      if (route.meta.desc) {
        let setDesc = route.meta.desc
        document
          .querySelector("meta[name='description']")
          .setAttribute('content', setDesc)
      }
    }
  }
}
</script>

<style>
:root {
  --line-color: #1e458a;
  --theme-green-color: #82992b;
  --theme-blue-color: #238ac9;
  --result-bg-color: #ffffff;
  --result-dark-color: #2f2f2f;
  /* --image-bg-color: #656565; */

  --image-bg-color: #5A5A5A;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* width: 1920px; */
  /* width: 100vw; */
  /* height: 100vh; */
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

body {
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
    'Hiragino Sans', 'BIZ UDPGothic', Meiryo, sans-serif;
  margin: 0;

  overflow-x: hidden;
  user-select: none;
  height: 100%;
}

.selectable {
  user-select: all;
}

pre a{
  display:contents;
}
/* pre span {
  user-select: all;
} */

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.debug {
  border: solid 1px black;
  box-sizing: border-box;
}

.line-right {
  border-right: solid thin var(--line-color);
}

.line-bottom {
  border-bottom: solid thin var(--line-color);
}

.ml13 {
  margin-left: 13px;
}

.mr8 {
  margin-right: 8px;
}

.mr18 {
  margin-right: 18px;
}

.ml8 {
  margin-left: 8px;
}

.ml10 {
  margin-left: 10px;
}

.ml18 {
  margin-left: 18px;
}

.ml43 {
  margin-left: 43px;
}

.ml70 {
  /* margin-left: 70px; */
}

.mt34 {
  margin-top: 34px;
}

.mt56 {
  margin-top: 56px;
}

.gallary-menu {
  /* height: 534px; */
  width: 1240px;
  display: flex;
  flex-direction: column;
  /* margin-right: 105px; */
}

.gallary-menu .title {
  color: var(--line-color);
  font-weight: bold;
  font-size: 18px;
  text-align: left;
}

.gallary-menu .menu {
  display: flex;
  justify-content: space-between;
  margin-top: 62px;
}

.gallary-menu .menu .item {
  width: 380px;
  height: 380px;
}

.gallary-menu .sub-footer {
  height: 30px;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.category {
  width: 1240px;
}

.category .header {
  display: flex;
  height: 40px;
  align-items: center;
}

.category .contents {
  position: relative;
  display: flex;
  /* justify-content: space-between; */
}

/* .category .header .title {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
} */

.dot-green {
  background-image: url('../public/img/dot-green.png');
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
}

.dot-blue {
  background-image: url('../public/img/dot-blue.png');
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
}

.dot-pink {
  background-image: url('../public/img/dot-pink.png');
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
}

.title20 {
  font-size: 20px;
  font-weight: bold;
}

.title24 {
  font-size: 24px;
  font-weight: bold;
}

.text20 {
  font-size: 20px;
  font-weight: normal;
}

.page>.header {
  height: 190px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hide {
  display: none;
}

.main-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.list-container {
  width: 100%;
  overflow: auto;
}

.list {
  display: flex;
}

.list .content {
  width: 260px;
  margin: 0 15px;
  /* height: 210px; */
}

.list .content>img {
  width: 260px;
  height: 195px;
  border-radius: 20px;
}
</style>
