<template>
  <div id="archive-index">
    <div class="header">
      <img class="title" src="/img/title-gallary.png" alt="" />
    </div>
    <div class="main-contents">
      <div class="gallary-menu ml70">
        <div class="title line-bottom">地域資源データベースギャラリー</div>
      </div>
      <div class="main-title" style="margin-top: 60px">
        <div class="title">{{ content.title }}</div>
        <pre class="desc" style="margin: 45px 0 86px 0">{{ content.desc }}</pre>
      </div>
      <div class="sub-categories">
        <templete
          v-for="(sc, index) in content.subCategories"
          :key="`sc_${index}`"
        >
          <div class="sub-category" v-if="sc.details.length > 0">
            <div class="title" style="margin: 0 0 60px 0">{{ sc.title }}</div>
            <dev class="details">
              <div
                class="detail"
                v-for="(detail, index2) in sc.details"
                :key="`details_${index}_${index2}`"
                @click.stop="$emit('findOne', detail.data_id)"
              >
                <img :src="imageUrl(detail.thumbnail, 'small')" alt="" />
                <div class="title" style="margin: 18px 0 38px 0">
                  {{ detail.title }}
                </div>
              </div>
            </dev>
            <div class="more-col">
              <div
                v-if="sc.moreUrl"
                class="url"
                @click="openMoreUrl(sc.moreUrl)"
              ></div>
            </div>
          </div>
        </templete>
      </div>
      <div class="sub-categories">
        <templete
          v-for="(option, index) in content.options"
          :key="`option_${index}`"
        >
        <div class="sub-category tl">
            <div class="title" style="margin: 0 0 60px 0">{{ option.title }}</div>
            <dev class="desc" v-html="option.desc">
            </dev>
        </div>
        </templete>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../../mixins/mixin'
export default {
  name: 'StoryDetail',
  mixins: [mixin],
  data: function () {
    return {
      iframeUrl: '',
      tamaImList: [],
      stories: [],
      content: {}
    }
  },
  async created() {
    console.log('created')
    // this.tamaImList = await this.getdata('/data/map_tama_im.json')
    // console.log('this.tamaImList', this.tamaImList)
    await this.$store.dispatch('fetchStories')
    this.stories = await this.$store.getters.stories
    console.log('this.stories', this.stories)
    this.content =
      this.stories[this.$route.params.index].contents[this.$route.params.index2]
    console.log('this.content', this.content)
  },
  methods: {
    openMoreUrl(url) {
      window.location = url
    },
    onOpenDetail(index, index2) {
      console.log('onOpenDetail', index, index2)
    },
    onClickImageMap(index) {
      console.log(index)
      const imageMaps = this.$store.getters['imageMaps']
      console.log(imageMaps)
      const imageMap = imageMaps[index]
      const query = { keywords: imageMap.keywords }
      this.$store.dispatch('setFromForm', false)
      this.$emit('find', query, imageMap.name)
      // this.$emit('find', "")
    },
    onClickRange(index) {
      console.log(`onClickRange=${index}`)
      const ages = this.$store.getters['ages']
      console.log('ages', ages)
      console.log(ages[index])
      const age = ages[index]
      const query = { f5: `${age.range[0]}～${age.range[1]}`, f5_opt: 'range' }
      this.$store.dispatch('setFromForm', false)
      this.$emit('find', query, age.name)
      // console.log(this.$store.getters["ages"][index]);
    },
    openTamashiDA() {
      // this.iframeUrl =
      //   'https://www.youtube.com/channel/UC6W5T3tm7JSJde7v_EB50bQ'
      //       let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      // width=1000,height=600,left=100,top=100`
      //       window.open(this.iframeUrl, '_blank', params)
    }
  }
}
</script>

<style scoped>
.dummy-bottom {
  height: 50px;
}
.main-title {
  width: 1240px;
}

.main-title .title {
  font-size: 50px;
}

.main-title > .desc {
  font-size: 30px;
  font-family: sans-serif;
  font-weight: normal;
  white-space: pre-wrap;
  text-align: left;
  margin: 0;
}

.sub-categories {
  width: 1240px;
  display: flex;
  flex-direction: column;
}
.sub-category {
}
.sub-category > .title {
  font-size: 30px;
  font-weight: bold;
  color: var(--line-color);
  text-align: left;
}

.details {
  width: 1240px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 0 -15px;
}

.detail {
  width: 280px;
  margin: 0 15px;
}

.detail > img {
  border-radius: 20px;
  width: 280px;
  height: 210px;
}
.detail > .title {
  width: 280px;
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.more-col {
  margin-bottom: 80px;
  display: flex;
  flex-direction: row-reverse;
}

.more-col .url {
  width: 148px;
  height: 30px;
  background-image: url(@/assets/more-url.png);
  background-repeat: no-repeat;
}

.sub-category.tl{
  display: flex;
  flex-direction: column;
  margin-bottom:100px;
}

.sub-category > .desc {
  text-align: left;
  font-size: 30px;
  font-family: sans-serif;
  font-weight: normal;
  white-space: pre-wrap;
  text-align: left;
  margin: 0;
}
</style>
