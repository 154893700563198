<template>
  <div class="small result" @click.stop="$emit('findOne',params.data_id)">
    <div class="container">
      <div class="aside">
        <el-image class="image" :src="thumbnail" fit="contain"
          ><template #error>
            <div class="image-slot">
              画像はありません
            </div>
          </template>
        </el-image>
      </div>
      <div class="main">
        <div class="title">{{ params.title }}</div>
        <pre class="desc">{{ params.f6 }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['params'],
  computed: {
    url() {
      return ''
    },
    thumbnail() {
      if (this.params.pict_main_data) {
        const title = this.params.pict_main_data.title
        if (title) {
          return `https://ibmuseum.mapps.ne.jp/files/2096/media_files/small/${title}`
        } else {
          return ''
        }
      } else {
        return ``
      }
    }
  }
}
</script>

<style scoped>
.result.small {
  width: 476px;
  height: 205px;
  border: solid 1px black;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
}
.container {
  margin: 20px;
}
.aside {
  width: 128px;
}

.image {
  width: 128px;
  height: 128px;
  background-color: var(--image-bg-color);
  display: flex;
  align-items: center;
    justify-content: center;
}

.main {
  margin-left: 30px;
}
.title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}
.desc {
  text-align: left;
  font-size: 16px;
  /* height: 140px; */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: pre-wrap ;
}

.image-slot{
  display: flex;
  font-size: 12px;
  /* color: white; */
}
</style>
