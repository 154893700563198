//api.jmapps.ne.jp/db/2096/1/shiryo?keywords=連光寺+聖ヶ丘&and_or=or&count_per_page=8

import axios from "axios";
import _ from "lodash";
import qs from "qs"
// import authHeader from "./auth-header";

class JmappsService {
  async find(query, count_per_page = 8, page) {
    if (query !== null) {
      try {
        const options = {
          and_or: "or",
          count_per_page,
        };
        if (page) {
          options.page = page;
        }
        const params = _.merge({}, query, options);
        console.log("params", params);
        const url = `${process.env.VUE_APP_SHIRYO_DB_API}/db/2096/1/shiryo`;
        const response = await axios.get(url, {
          params,
          paramsSerializer: function (params) {
            /* ... */
            console.log("key", params.keywords);
            let str = qs.stringify(params, { format: "RFC1738" })
            console.log("++++qs", str);

            return str;
          },
        });
        console.log(response);
        if (!(response instanceof Error)) {
          return response.data;
        } else {
          throw response;
        }
      } catch (error) {
        console.log(error);
        console.log("ERROR!! occurred in Backend.!");
        throw error;
      }
    }
  }
  async findByID(data_id) {
    if (data_id) {
      try {
        const url = `${process.env.VUE_APP_SHIRYO_DB_API}/db/2096/1/shiryo/${data_id}`;
        const response = await axios.get(url);
        if (!(response instanceof Error)) {
          return response.data;
        } else {
          throw response;
        }
      } catch (error) {
        console.log(error);
        console.log("ERROR!! occurred in Backend.!");
        throw error;
      }
    }
  }

  async fetchBunrui() {
    try {
        const url = `${process.env.VUE_APP_SHIRYO_DB_API}/db/2096/1/bunrui/`;
        const response = await axios.get(url);
        if (!(response instanceof Error)) {
          return response.data;
        } else {
          throw response;
        }
      } catch (error) {
        console.log(error);
        console.log("ERROR!! occurred in Backend.!");
        throw error;
      }
  }

  // async findByID(id) {
  //   try {
  //     const response = await axios.get(`/api/story/${id}`, {
  //       headers: authHeader(),
  //     });
  //     if (!(response instanceof Error)) {
  //       return response.data;
  //     } else {
  //       throw response;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     console.log("ERROR!! occurred in Backend.!");
  //     throw error;
  //   }
  // }

  // async deleteByID(id) {
  //   try {
  //     const response = await axios.delete(`/api/story/${id}`, {
  //       headers: authHeader(),
  //     });
  //     if (!(response instanceof Error)) {
  //       return response.data;
  //     } else {
  //       throw response;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     console.log("ERROR!! occurred in Backend.!");
  //     throw error;
  //   }
  // }
}

export default new JmappsService();
