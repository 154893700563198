<template>
  <div class="default-layout">
    <div class="container">
      <div class="aside right-line small-hide" id="aside">
        <div class="menu">
          <div
            class="logo bottom-line"
            @click="$router.push({ name: 'index' })"
            style="cursor: pointer"
          ></div>
          <router-link
            to="/"
            class="item bottom-line"
            :class="{ hilited: $route.path == '/' }"
            >地域資源データベースギャラリー</router-link
          >
          <router-link
            to="/area"
            class="item bottom-line"
            :class="{ hilited: $route.path == '/area' }"
            >地域資源マップ</router-link
          >
          <router-link
            to="/collection"
            class="item bottom-line"
            :class="{ hilited: $route.path == '/collection' }"
            >古い写真や映像を収集しています</router-link
          >
          <div
            class="copyright bottom-line"
            @click="goShashin"
            style="cursor: pointer"
          >
            <img src="../../public/img/logo.png" alt="" class="small-logo" />
            <div
              class="label"
              href="https://www.parthenon.or.jp/facility/syashin/"
            >
              当館所蔵写真資料の利用について
            </div>
          </div>
          <div class="media" @click="goAbout">
            <a href="#" class="icon"
              ><img src="../../public/img/icon-facebook.png" alt=""
            /></a>
            <a href="#" class="icon"
              ><img src="../../public/img/icon-twitter.png" alt=""
            /></a>
            <a href="#" class="icon"
              ><img src="../../public/img/icon-instagram.png" alt=""
            /></a>
            <a href="#" class="icon"
              ><img
                src="../../public/img/icon-youtube.png"
                alt=""
                style="width: 72px"
            /></a>
          </div>
          <router-link
            to="/about"
            class="about"
            :class="{ hilited: $route.path == '/about' }"
          >
            このサイトについて
          </router-link>
        </div>
      </div>
      <div class="main-container">
        <router-view
          class="main"
          @find="onFind"
          @findOne="onFindOne"
          @openForm="onOpenForm"
        ></router-view>
      </div>
      <div class="top-container" v-if="query !== null" style="z-index: 2">
        <div class="dummy-bg" @click.stop="resetSearch"></div>
        <ResultList
          class="result-list"
          :query="query"
          :title="searchTitle"
          @click.stop="onDummy"
          @findOne="onFindOne"
          @close="resetSearch"
          @histryBack="histryBackForm"
        ></ResultList>
      </div>
      <div class="top-container" v-if="queryOne !== null" style="z-index: 2">
        <div class="dummy-bg" @click.stop="resetSearchOne"></div>
        <ResultOne
          class="result-list"
          :data_id="queryOne"
          :title="searchTitleOne"
          @click.stop="onDummy"
          @findOne="onFindOne"
          ref="resultOne"
          @close="resetSearchOne"
          @histryBack="histryBack"
        ></ResultOne>
      </div>
      <div class="top-container" v-if="isForm" style="z-index: 2">
        <div class="dummy-bg" @click.stop="isForm = false"></div>
        <SearchForm
          class="form"
          @find="onFind"
          @close="isForm = false"
        ></SearchForm>
      </div>
    </div>

    <footer class="footer">
      Copyright Tama City Cultural Foundation. All rights reserved.
    </footer>

    <div class="navigation">
      <button class="back-btn" @click="onBack" :disabled="isHome"></button>
      <button class="home-btn" @click="onGoHome" :disabled="isHome"></button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import ResultList from '../components/ReultList'
import ResultOne from '../components/ResultOne'
import SearchForm from '../components/SearchForm'
export default {
  name: 'DefalutLayout',
  components: { ResultList, ResultOne, SearchForm },
  setup() {
    onMounted(() => {
      console.log('mounted in the composition api!')
    })
    const query = ref(null)
    const searchTitle = ref(null)
    const queryOne = ref(null)
    const searchTitleOne = ref(null)
    const isForm = ref(false)
    const queryCache = ref([])
    return {
      query,
      searchTitle,
      queryOne,
      searchTitleOne,
      isForm,
      queryCache
    }
  },
  computed: {
    isHome() {
      return this.$route.name == 'index'
    }
  },
  methods: {
    histryBackForm() {
      this.isForm = this.$store.getters['fromForm']
      this.query = null
    },
    histryBack() {
      let prev = this.queryCache[this.queryCache.length - 2]
      console.log('prev', prev)
      if (prev.type == 'queryOne') {
        this.queryOne = null
        const self = this
        setTimeout(() => {
          self.queryOne = prev.query
        }, 0)
        this.queryCache.pop()
      } else if (prev.type == 'query') {
        this.queryOne = null
        this.query = prev.query
        this.queryCache.pop()
      }
    },
    addCache(type, query) {
      this.queryCache.push({ type, query })
    },
    clearCache() {
      this.queryCache = []
    },
    goShashin() {
      console.log('goSHashin')
      window.location.href = 'https://www.parthenon.or.jp/facility/syashin/'
    },
    goAbout() {
      console.log('goSHashin')
      this.$router.push({ name: 'about' })
    },
    onOpenForm() {
      this.resetSearch()
      this.resetSearchOne()
      this.isForm = true
      this.clearCache()
    },
    onBack() {
      this.queryOne = null
      this.query = null
      this.$router.go(-1)
    },
    onGoHome() {
      this.queryOne = null
      this.query = null
      this.$router.push({ name: 'index' })
    },
    onFind(query, title) {
      console.log(`onFind=${query}`)
      console.log(`title=${title}`)
      this.queryOne = null
      this.searchTitleOne = null
      this.isForm = false
      this.query = query
      this.searchTitle = title
      this.addCache('query', query)
    },
    onFindOne(data_id, title) {
      console.log(`DefaultLayout.onFindOne=${data_id}`)
      console.log(`title=${title}`)
      this.isForm = false
      this.query = null
      this.searchTitle = null
      this.queryOne = null
      this.searchTitleOne = null
      const self = this
      setTimeout(() => {
        self.queryOne = data_id
        self.searchTitle = ''
        self.addCache('queryOne', self.queryOne)
      }, 0)
      // this.queryOne = data_id
      // this.searchTitle = ''
      // if (this.$refs['resultOne']) {
      //   this.$refs['resultOne'].update()
      // }
    },
    resetSearch() {
      this.query = null
      this.searchTitle = null
    },
    resetSearchOne() {
      this.queryOne = null
      this.searchTitleOne = null
    },
    onDummy() {
      console.log('onDummy')
    },
    onUsage() {
      window.location = 'https://www.parthenon.or.jp/facility/syashin/'
    }
  }
}
</script>

<style scoped>
.main-container {
  position: relative;
  /* width: calc(100% - 523px); */
  /* width: 100%; */
  height: 100%;
  overflow: auto;
  flex: 1;
}
.main {
  /* flex-grow: 2; */
  flex: 1;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.back-btn {
  width: 126px;
  height: 68px;
  background-image: url('@/assets/back-btn.png');
  background-repeat: no-repeat;
}

.back-btn:disabled {
  opacity: 0.2;
}

.home-btn {
  width: 75px;
  height: 68px;
  background-image: url('@/assets/home-btn.png');
  background-repeat: no-repeat;
}

.home-btn:disabled {
  opacity: 0.2;
}
</style>
<style>
.public {
  width: 100%;
  height: 100%;
}

.default-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-position-x: right;
  background-position-y: top;
  background-attachment: fixed;
  background-image: url('@/assets/corner.png');
  background-repeat: no-repeat;
  background-size: 691px 742px;
}
.container {
  width: 100%;
  display: flex;
  flex-grow: 2;
  height: calc(100vh - 50px);
}
.aside {
  width: 523px;
}

.navigation {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 203px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1601px) {
  .main {
    min-height: 800px;
  }
  /* 横幅が320px以下の場合に適用するスタイル */
  .small-hide {
    display: none;
  }

  .small {
    width: 100%;
  }
  /* 小画面のときだけ表示 */
  .navigation {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 203px;
    display: flex;
    justify-content: space-between;
    display: none;
  }
}

.right-line {
  border-right: solid thin var(--line-color);
}
.bottom-line {
  border-bottom: solid thin var(--line-color);
}

.footer {
  width: 100%;
  min-height: 50px;
  background-color: var(--line-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.aside .menu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.aside .menu .item {
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  background-color: white;
  background-image: none;
  color: black;
  text-decoration: none;
}

.aside .menu .item.hilited {
  background-image: url('../../public/img/menu-hilite.png');
  background-repeat: no-repeat;
  color: white;
}

.aside .menu .logo {
  height: 210px;
  background-image: url('../../public/img/logo.png');
}

.aside .menu .margin {
  flex-grow: 2;
}

.aside .media {
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.aside .media .icon {
  height: 58px;
  width: 100px;
  display: flex;
  align-items: center;
}
.aside .copyright {
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aside .small-logo {
  width: 243px;
  height: auto;
}
.aside .copyright .label {
  margin-top: 15px;
  text-decoration: none;
  color: black;
}

.aside .about {
  margin-top: 170px;
  margin-bottom: 23px;
  text-decoration: none;
  color: black;
}

.top-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* pointer-events: none; */
}

.dummy-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.top-container .result-list {
  /* position: absolute;
  top: 50px;
  left: 50px; */
  /* pointer-events: none; */
  z-index: 100;
}

.top-container .form {
  /* position: absolute;
  top: 50px;
  left: 50px; */
  /* pointer-events: none; */
  z-index: 100;
}
</style>
