import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueEasyLightbox from "vue-easy-lightbox";

  import { dragscrollNext } from "vue-dragscroll";

// import "./plugins/axios.js";

console.log("+++ main");
// store.dispatch("fetchStories");
// store.dispatch("fetchImageMaps");
// store.dispatch("fetchAges");

import VueGoogleMaps from "@fawmi/vue-google-maps";

createApp(App)
  .use(store)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
    },
  })
  .use(ElementPlus)
  .use(VueEasyLightbox)
  .directive("dragscroll", dragscrollNext)
  .mount("#app");
