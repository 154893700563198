import { createRouter, createWebHistory } from "vue-router";
import Index from "../views/IndexView.vue";
import About from "../views/AboutView.vue";
import Collection from "../views/CollectionView.vue";
import AreaIndex from "../views/area/Index.vue";
import ArchiveIndex from "../views/archive/Index.vue";
import ArchiveMap from "../views/archive/Map.vue";
import ArchiveAge from "../views/archive/Age.vue";
import ArchiveStory from "../views/archive/Story.vue";
import ArchiveStoryDetail from "../views/archive/story/Detail.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: { layout: "default" },
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/collection",
    name: "collection",
    component: Collection,
  },
  {
    path: "/area",
    name: "area",
    component: AreaIndex,
  },
  {
    path: "/archive",
    name: "archive-index",
    component: ArchiveIndex,
  },
  {
    path: "/archive/map",
    name: "archive-map",
    component: ArchiveMap,
  },
  {
    path: "/archive/age",
    name: "archive-age",
    component: ArchiveAge,
  },
  {
    path: "/archive/story",
    name: "archive-story",
    component: ArchiveStory,
  },
  {
    path: "/archive/story/detail/:index/:index2",
    name: "archive-story-detail",
    component: ArchiveStoryDetail,
  },
];

const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        return savedPosition;
    } else {
        return { top: 0 };
    }
};

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior,
});


export default router;
