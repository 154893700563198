<template>
  <div class="result list">
    <div class="header">
     パルテノン多摩ミュージアム地域資源データベース
    </div>
    <img
      src="/img/return-btn.png"
      alt=""
      srcset=""
      class="return-btn"
      @click="$emit('histryBack')"
    />
    <img
      src="/img/close-btn.png"
      alt=""
      srcset=""
      class="close-btn"
      @click="$emit('close')"
    />

    <div class="main">
      <div class="bread">{{ title }}/検索結果一覧</div>
      <div class="pagenation">
        <button
          class="button-l first"
          :disabled="currentPage <= 1"
          @click="onFirst"
        ></button>
        <button
          class="button-s prev"
          :disabled="currentPage <= 1"
          @click="onPrev"
        ></button>
        <div class="page">{{ currentPage }}/{{ maxPages }}</div>
        <button
          class="button-s next"
          :disabled="currentPage == maxPages"
          @click="onNext"
        ></button>
        <button
          class="button-l last"
          :disabled="currentPage == maxPages"
          @click="onLast"
        ></button>
      </div>
      <div class="results" v-if="result.hit_count != 0">
        <!-- <div
          v-for="item in result.data"
          :key="item.data_id"
          :params="item"
          class="rs"
        >
          {{ item }}
        </div> -->
        <ResultSmall
          v-for="item in result.data"
          :key="item.data_id"
          :params="item"
          class="rs"
          @findOne="onFindOne"
        ></ResultSmall>
      </div>
      <div v-else>検索結果はありません</div>
    </div>
  </div>
</template>

<script>
import ResultSmall from './ResultSmall.vue'
import JmapsSertvice from '../services/Jmaps.service'
export default {
  props: ['query', 'title'],
  components: { ResultSmall },
  data() {
    return { result: {}, currentPage: null, count_per_page: 6 }
  },
  computed: {
    maxPages() {
      return Math.ceil(this.result.hit_count / this.count_per_page)
    }
  },
  async created() {
    console.log('ResultList.created')
    console.log('query', this.query)
    const res = await JmapsSertvice.find(this.query, this.count_per_page)
    this.result = res
    console.log('this.result', this.result)
    if (this.result.hit_count != 0) {
      this.currentPage = 1
    } else {
      this.currentPage = 0
    }
  },
  mounted() {
    console.log('ResultList.mounted')
  },
  methods: {
    onFindOne(data_id) {
      console.log(data_id)
      this.$emit('findOne', data_id)
    },
    async onFirst() {
      this.currentPage = 1
      const res = await JmapsSertvice.find(
        this.query,
        this.count_per_page,
        this.currentPage
      )
      this.result = res
    },
    async onLast() {
      this.currentPage = this.maxPages
      const res = await JmapsSertvice.find(
        this.query,
        this.count_per_page,
        this.currentPage
      )
      this.result = res
    },

    async onPrev() {
      this.currentPage--
      if (this.currentPage < 1) {
        this.currentPage = 1
      }
      const res = await JmapsSertvice.find(
        this.query,
        this.count_per_page,
        this.currentPage
      )
      this.result = res
    },

    async onNext() {
      this.currentPage++
      if (this.currentPage > this.maxPages) {
        this.currentPage = this.maxPages
      }
      const res = await JmapsSertvice.find(
        this.query,
        this.count_per_page,
        this.currentPage
      )
      this.result = res
    }
  }
}
</script>

<style scoped>
.result.list {
  background-color: white;
  width: 1115px;
  height: 872px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* pointer-events: none; */
}
.header {
  height: 57px;
  background-color: var(--result-dark-color);
  color: white;
  font-size: 27px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 57px;
}
.main {
  width: 989px;
  display: flex;
  flex-direction: column;
}
.bread {
  font-size: 20px;
  display: flex;
  margin-top: 16px;
}
.page {
  font-size: 20px;
  display: flex;
  margin: 0 7.5px;
}
.pagenation {
  display: flex;
  font-size: 20px;
  justify-content: center;
  margin-top: 35px;
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  margin-bottom: 14px;
}
.button-l {
  width: 58px;
  height: 28px;
  margin: 0 7.5px;
}
.button-s {
  width: 52px;
  height: 28px;
  margin: 0 7.5px;
}
.first {
  background-image: url(@/assets/first.png);
  background-repeat: no-repeat;
}

.first:disabled {
  background-image: url(@/assets/first-disabled.png);
  background-repeat: no-repeat;
}
.prev {
  background-image: url(@/assets/prev.png);
  background-repeat: no-repeat;
}

.prev:disabled {
  background-image: url(@/assets/prev-disabled.png);
  background-repeat: no-repeat;
}
.next {
  background-image: url(@/assets/next.png);
  background-repeat: no-repeat;
}
.next:disabled {
  background-image: url(@/assets/next-disabled.png);
  background-repeat: no-repeat;
}
.last {
  background-image: url(@/assets/last.png);
  background-repeat: no-repeat;
}

.last:disabled {
  background-image: url(@/assets/last-disabled.png);
  background-repeat: no-repeat;
}
.results {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rs {
  margin-bottom: 20px;
}
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.return-btn {
  position: absolute;
  top: 15px;
  left: 15px;
}
</style>
