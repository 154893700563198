<template>
  <div class="result list">
    <div class="header">
     パルテノン多摩ミュージアム地域資源データベース
    </div>
    <img
      src="/img/close-btn.png"
      alt=""
      srcset=""
      class="close-btn"
      @click="$emit('close')"
    />
    <div class="main" style="margin-top: 60px">
      <div class="form">
        <div class="col">
          <div class="label">キーワード</div>
          <div class="item">
            <input type="text" v-model="form.keywords" class="w1" />
          </div>
        </div>
        <div class="col">
          <div class="label"></div>
          <div class="item radio">
            <div class="group">
              <input
                type="radio"
                id="and"
                v-model="form.and_or"
                value="and"
                class="rb"
              />
              <label for="and" class="rl">全ての語を含む</label>
            </div>
            <div class="group">
              <input
                type="radio"
                id="or"
                v-model="form.and_or"
                value="or"
                class="rb"
              />
              <label for="or" class="rl">いずれかの語を含む</label>
            </div>
          </div>
        </div>

        <!-- <div class="col">
          <div class="label">分類</div>
          <div class="item">
            <select v-model="form.bunrui" id="pet-select" class="w1">
              <option value="">すべて</option>
              <option value="1">写真</option>
              <option value="5">自然史</option>
            </select>
          </div>
        </div> -->
        <div class="col">
          <div class="label">大分類</div>
          <div class="item">
            <!-- <input type="text" v-model="form.f1" class="w1" /> -->
            <select name="pets" id="pet-select" v-model="form.f1" class="w1">
              <option value="">なし</option>
              <option
                :value="item.contents"
                v-for="(item, index) in bunrui"
                :key="`dai_${index}`"
              >
                {{ item.contents }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="label">中分類</div>
          <div class="item">
            <!-- <input type="text" v-model="form.f2" class="w1" /> -->
            <select name="pets" id="pet-select" v-model="form.f2" class="w1">
              <option value="">なし</option>
              <option
                :value="item.contents"
                v-for="(item, index) in chubunrui"
                :key="`chu_${index}`"
              >
                {{ item.contents }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="label">資料名</div>
          <div class="item">
            <input type="text" v-model="form.title" class="w1" />
          </div>
        </div>
        <div class="col">
          <div class="label">年月日</div>
          <div class="item items">
            <div><input type="text" v-model="start" /></div>
            <div class="sep">〜</div>
            <div><input type="text" v-model="end" /></div>
          </div>
        </div>
        <div class="col">
          <div class="label"></div>
          <div class="item">
            <div class="group">
              <input
                type="checkbox"
                id="img"
                v-model="form.img"
                true-value="1"
                false-value=""
                class="rb"
              /><label for="img" class="rl">画像有のみを表示</label>
            </div>
          </div>
        </div>
        <div class="col centering" style="margin-top: 55px">
          <button class="blue" @click="onSubmit">検索</button>
          <button class="black" @click="reset">リセット</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JmapsService from '@/services/Jmaps.service'
export default {
  name: 'searchForm',
  data() {
    return {
      start: '',
      end: '',
      bunrui: [],
      chubunrui: [],
      form: {
        keywords: '',
        and_or: 'and',
        title: '',
        f1: '',
        f2: '',
        img: ''
      }
    }
  },
  watch: {
    'form.f1': function (val) {
      console.log('form.f1', val)
      const obj = this.bunrui.find((item) => {
        return item.contents == val
      })
      console.log(obj)
      if (obj) {
        this.chubunrui = obj.bunrui_data
      } else {
        this.chubunrui = []
      }
      this.form.f2 = ''
    }
  },
  // computed: {
  //   form: {
  //     set: function (value) {
  //       this.$store.dispatch('setLastForm', value)
  //     },
  //     get: function () {
  //       return this.$store.getters['lastForm']
  //     }
  //   }
  // },
  async created() {
    this.bunrui = await JmapsService.fetchBunrui()
    console.log('this.bunrui', this.bunrui)
    this.form = this.$store.getters['lastForm']
  },
  methods: {
    reset() {
      this.start = ''
      this.end = ''
      this.form = {
        keywords: '',
        and_or: 'and',
        title: '',
        f1: '',
        f2: '',
        // bunrui: '',
        img: ''
      }
    },
    onSubmit() {
      if (this.start || this.end) {
        this.form.f5 = `${this.start}～${this.end}`
        this.form.f5_opt = 'range'
      }

      this.form.keywords = this.form.keywords.replace(/　/g, ' ') // eslint-disable-line
      console.log('this.form', this.form)
      this.$store.dispatch('setLastForm', this.form)
      this.$store.dispatch('setFromForm', true)
      this.$emit('find', this.form)
    }
  }
}
</script>


<style scoped>
.result.list {
  background-color: white;
  width: 1115px;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* pointer-events: none; */
}
.header {
  height: 57px;
  background-color: var(--result-dark-color);
  color: white;
  font-size: 27px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  line-height: 57px;
}
.main {
  width: 989px;
  display: flex;
  flex-direction: column;
}
.form {
  width: 100%;
  font-size: 20px;
}
.col {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 40px;
}
.col .label {
  width: 200px;
  text-align: right;
  padding: 0 20px 0 0;
}

.col .item {
  flex: 1;
  text-align: left;
}

.col .item input,
select {
  /* width: 100%; */
  height: 40px;
  padding: 0 10px;
  font-size: 20px;
}

.radio {
  display: flex;
}
.group {
  display: flex;
  align-items: center;
}

.w1 {
  width: 730px;
}

.rl {
  margin: 0 40px 0 20px;
}

.rb {
  width: 26px;
  height: 26px;
}

.items {
  display: flex;
  align-items: center;
}

.sep {
  margin: 0 10px;
}

.centering {
  display: flex;
  justify-content: center;
}

.centering > button {
  width: 240px;
  height: 60px;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 0 10px;
}

.blue {
  background-color: #238ac9;
}
.black {
  background-color: black;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
