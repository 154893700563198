<template>
  <div class="result" @click="$emit('close')">
    <div class="container">
      <div class="aside">
        <el-image
          class="image"
          :src="url"
          fit="contain"
          @click="visible = true"
        >
          <template #error>
            <div class="image-slot">画像はありません</div>
          </template>
        </el-image>
        <img src="/img/zoom.png" alt="" srcset="" class="zoom" />
      </div>
      <div class="main">
        <div class="title">{{ title }}</div>
        <pre class="desc">{{ desc }}</pre>
      </div>
    </div>
    <vue-easy-lightbox
      :visible="visible"
      :imgs="[largeUrl]"
      index="0"
      @hide="visible = false"
    >
      <template v-slot:toolbar="">
        <!-- <button @click="toolbarMethods.zoomIn">zoom in</button>
        <button @click="toolbarMethods.zoomOut">zoom out</button>
        <button @click="toolbarMethods.rotateLeft">
          Anticlockwise rotation
        </button>
        <button @click="toolbarMethods.rotateRight">clockwise rotation</button> -->
      </template>
    </vue-easy-lightbox>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'resultLearge',
  props: ['params'],
  data() {
    return {
      visible: false
    }
  },
  computed: {
    title() {
      if (_.get(this.params, 'title')) {
        return this.params.title
      } else {
        return ''
      }
    },
    desc() {
      if (_.get(this.params, 'f6')) {
        return this.params.f6
      } else {
        return ''
      }
    },
    url() {
      // console.log('url', this.params.pict_data)
      if (_.get(this.params, 'pict_data.data[0].title')) {
        return `https://ibmuseum.mapps.ne.jp/files/2096/media_files/mid/${this.params.pict_data.data[0].title}`
      } else {
        return ''
      }
    },
    largeUrl() {
      // console.log('url', this.params.pict_data)
      if (_.get(this.params, 'pict_data.data[0].title')) {
        return `https://ibmuseum.mapps.ne.jp/files/2096/media_files/large/${this.params.pict_data.data[0].title}`
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped>
.result {
  width: 990px;
  height: 460px;
  background-color: var(--result-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.result .container {
  display: flex;
  flex-direction: row;
  height: 382px;
}

.result .aside {
  background-color: var(--result-bg-color);
  width: 382px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  position: relative;
}

.result .aside .image {
  width: 384px;
  height: 384px;
  background-color: var(--image-bg-color);
}

.result .main {
  background-color: var(--result-bg-color);
  margin: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
}
.result .main .title {
  background-color: var(--result-bg-color);
  text-align: left;
  /* height: 50px; */
  font-size: 26px;
  font-weight: bold;
}
.result .main .desc {
  background-color: var(--result-bg-color);
  text-align: left;
  margin-top: 25px;
  flex: 1;
  font-size: 16px;
  white-space: pre-wrap;
}
.zoom {
  position: absolute;
  left: 340px;
  bottom: 6px;
  pointer-events: none;
}
</style>
