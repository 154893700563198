<template>
  <div id="archive-index">
    <div class="header">
      <img class="title" src="/img/title-gallary.png" alt="" />
    </div>
    <div class="main-contents">
      <div class="gallary-menu ml70">
        <div class="title line-bottom">地域資源データベースギャラリー</div>
      </div>
      <!-- <div class="category ml70 mt56">
      <div class="header line-bottom">
        <div class="dot-green"></div>
        <div class="title24 ml10">詳しく探す</div>
      </div>
      <div class="menu" style="margin-top: 65px">
        <button class="kuwashiku lbutton">
          パルテノン多摩ミュージアム地域資源データベース
        </button>
        <button class="kuwashiku lbutton" @click="openTamashiDA">
          多摩市デジタルアーカイブ
        </button>
      </div>
    </div> -->
      <div class="category ml70 mt56">
        <div class="header line-bottom">
          <div class="dot-green"></div>
          <div class="title24 ml10">地図から地域資源を見る</div>
        </div>
        <div class="contents">
          <div class="map-tama" style="margin-top: 40px">
            <div class="title">多摩市内</div>
            <img
              src="/img/map_tama.png"
              usemap="#ImageMap"
              alt=""
              class="base"
            />
            <map name="ImageMap">
              <area
                shape="poly"
                coords="496,38,493,66,515,74,449,88,435,98,407,158,429,171,441,169,463,222,467,239,499,245,500,252,507,251,519,224,513,218,535,205,543,192,554,194,564,187,562,177,543,128,548,101,580,83,578,69,539,53,539,53"
                alt=""
                @click="onClickImageMap(0)"
              />
              <area
                shape="poly"
                coords="339,4,325,37,327,54,343,66,352,66,364,59,389,66,388,76,402,78,410,83,405,91,397,90,394,98,397,103,377,147,384,156,385,163,394,166,404,160,419,120,430,99,442,91,510,75,491,68,493,41,357,-1,357,-1"
                alt=""
                @click="onClickImageMap(1)"
              />
              <area
                shape="poly"
                coords="495,249,484,273,468,282,457,297,451,294,445,300,437,300,417,281,407,262,399,201,399,195,382,179,400,168,405,161,423,171,439,169,459,218,465,241,465,241"
                alt=""
                @click="onClickImageMap(2)"
              />
              <area
                shape="poly"
                coords="172,62,216,86,224,89,243,103,251,88,267,91,281,78,277,77,288,75,300,56,324,56,337,67,353,69,364,65,386,67,387,74,364,77,349,70,337,79,336,118,327,129,326,154,329,157,324,167,315,165,259,171,236,146,201,115,164,95,161,79,161,79"
                alt=""
                @click="onClickImageMap(3)"
              />
              <area
                shape="poly"
                coords="352,75,337,81,338,118,329,129,327,151,335,158,353,166,361,147,372,139,376,147,380,140,385,124,398,104,391,98,397,90,402,88,406,83,396,77,363,79,363,79"
                alt=""
                @click="onClickImageMap(4)"
              />
              <area
                shape="poly"
                coords="379,179,397,198,405,262,418,284,438,301,430,302,400,348,397,348,371,372,360,368,356,350,355,324,353,295,358,280,352,269,356,191,356,188,356,188"
                alt=""
                @click="onClickImageMap(5)"
              />
              <area
                shape="poly"
                coords="259,176,304,169,322,172,332,164,355,170,362,149,373,145,375,154,381,155,386,165,395,168,345,191,300,191,271,209,266,222,261,217,252,220,212,231,220,220,213,217,227,207,251,205,264,192,264,192"
                alt=""
                @click="onClickImageMap(6)"
              />
              <area
                shape="poly"
                coords="277,359,352,351,347,327,350,292,355,283,348,273,354,191,332,193,298,192,271,209,266,221,284,286,274,328,274,328"
                alt=""
                @click="onClickImageMap(7)"
              />
              <area
                shape="poly"
                coords="287,396,319,363,341,362,355,369,350,354,275,361,271,324,283,283,262,217,197,242,176,240,130,312,132,338,145,343,162,340,171,352,188,361,203,378,212,383,220,381,228,390,230,393,243,387,249,378,257,377,257,377"
                alt=""
                @click="onClickImageMap(8)"
              />
              <area
                shape="poly"
                coords="2,364,10,364,20,354,51,353,57,361,83,342,104,339,119,353,130,344,128,313,175,240,198,240,214,232,216,220,214,218,214,218,207,223,197,213,167,231,146,233,135,246,114,249,100,261,99,271,74,273,74,283,37,334,11,340,2,362,2,362"
                alt=""
                @click="onClickImageMap(9)"
              />
            </map>
            <img src="/img/map_tama_title.png" alt="" class="img-title" />
          </div>
          <div class="map-newtown" style="margin-top: 40px">
            <div class="title">多摩ニュータウン全域</div>
            <img
              src="/img/map-newtown.png"
              usemap="#ImageMap2"
              alt=""
              class="base"
              style="margin-top: 30px"
            />
            <map name="ImageMap2">
              <area
                shape="poly"
                coords="513,96,514,110,510,129,518,134,527,148,552,152,583,134,588,115,619,98,625,74,651,37,644,24,623,11,622,19,612,9,608,2,604,2,605,15,588,39,591,45,604,67,598,72,575,78,573,88,559,84,557,104,537,107,528,92,528,92"
                @click="onClickImageMap(10)"
                alt=""
              />
              <area
                shape="poly"
                coords="353,83,365,97,296,140,291,150,270,165,254,188,239,217,223,227,214,246,198,258,191,293,183,284,156,271,153,267,126,269,117,272,109,263,96,266,76,273,61,268,57,263,35,255,30,260,10,250,1,229,29,228,80,208,84,184,139,162,143,157,178,126,190,130,201,104,214,107,223,100,216,94,216,85,245,86,261,108,267,114,292,99,305,100,305,100"
                @click="onClickImageMap(11)"
                alt=""
              />
              <area
                shape="poly"
                coords="10,252,24,262,39,258,58,264,61,273,75,273,103,267,118,277,128,273,149,272,175,284,185,288,203,314,194,319,163,313,62,297,10,265,10,265"
                @click="onClickImageMap(12)"
                alt=""
              />
            </map>
          </div>
          <!-- <button class="sonota">その他</button> -->
        </div>
      </div>
      <!-- <div class="category ml70" style="margin-top: 53px">
      <div class="header line-bottom">
        <div class="dot-blue"></div>
        <div class="title24 ml10">年代から地域資源を見る</div>
      </div>
      <div class="menu" style="margin-top: 100px">
        <button class="nendai nbutton" @click="onClickRange(0)">
          中世以前
        </button>
        <button class="nendai nbutton" @click="onClickRange(1)">近世</button>
        <button class="nendai nbutton" @click="onClickRange(2)">
          明治時代～1950年代
        </button>
      </div>
      <div class="menu" style="margin-top: 39px">
        <button class="nendai nbutton" @click="onClickRange(3)">
          1960年代～1970年代
        </button>
        <button class="nendai nbutton" @click="onClickRange(4)">
          1980年代～1990年代
        </button>
        <button class="nendai nbutton" @click="onClickRange(5)">
          2000年代以降
        </button>
      </div>
    </div>

    <div class="category ml70" style="margin-top: 100px">
      <div class="header line-bottom">
        <div class="dot-pink"></div>
        <div class="title24 ml10">ストーリーから地域資源を見る</div>
      </div>
      <div
        class="story"
        v-for="(story, index) in stories"
        :key="`story_${index}`"
        v-show="story.contents.length > 0"
      >
        <div class="header">{{ story.title }}</div>
        <div class="contents">
          <button><img src="/img/arrow-right.png" alt="" /></button>
          <ul class="list">
            <li
              class="content"
              v-for="(content, index2) in story.contents"
              :key="`contents_${index}_${index2}`"
            >
              <img :src="content.thumbnail" alt="" />
              <div class="title">{{ content.title }}</div>
            </li>
          </ul>
          <button><img src="/img/arrow-left.png" alt="" /></button>
        </div>
      </div>
    </div>
    <div class="iframe" v-if="this.iframeUrl != ''">
      <iframe :src="iframeUrl" frameborder="0"></iframe>
    </div> -->
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins/mixin'
export default {
  name: 'ArchiveIndex',
  mixins: [mixin],
  data: function () {
    return {
      iframeUrl: '',
      tamaImList: [],
      stories: []
    }
  },
  async created() {
    // console.log("created");
    // this.tamaImList = await this.getdata('/data/map_tama_im.json')
    // console.log('this.tamaImList', this.tamaImList)
    this.stories = await this.$store.getters.stories
    // console.log(this.stories.length);
  },
  methods: {
    onClickImageMap(index) {
      console.log(index)
      const imageMaps = this.$store.getters['imageMaps']
      console.log(imageMaps)
      const imageMap = imageMaps[index]
      const query = { keywords: imageMap.keywords }
      this.$store.dispatch('setFromForm', false)
      this.$emit('find', query, imageMap.name)
      // this.$emit('find', { keywords: '' }, '')
    },
    onClickRange(index) {
      console.log(`onClickRange=${index}`)
      const ages = this.$store.getters['ages']
      console.log('ages', ages)
      console.log(ages[index])
      const age = ages[index]
      const query = { f5: `${age.range[0]}～${age.range[1]}`, f5_opt: 'range' }
      this.$store.dispatch('setFromForm', false)
      this.$emit('find', query, age.name)
      // console.log(this.$store.getters["ages"][index]);
    },
    openTamashiDA() {
      // this.iframeUrl =
      //   'https://www.youtube.com/channel/UC6W5T3tm7JSJde7v_EB50bQ'
      //       let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      // width=1000,height=600,left=100,top=100`
      //       window.open(this.iframeUrl, '_blank', params)
    }
  }
}
</script>
