<template>
  <div id="archive-index">
    <div class="header">
      <img class="title" src="/img/title-gallary.png" alt="" />
    </div>
    <div class="main-contents">
      <div class="gallary-menu ml70">
        <div class="title line-bottom">地域資源データベースギャラリー</div>
      </div>
      <div class="category ml70" style="margin-top: 53px">
        <div class="header line-bottom">
          <div class="dot-blue"></div>
          <div class="title24 ml10">年代から地域資源を見る</div>
        </div>
        <div class="menu" style="margin-top: 100px">
          <button class="nendai nbutton" @click="onClickRange(0)">
            中世以前
          </button>
          <button class="nendai nbutton" @click="onClickRange(1)">近世</button>
          <button class="nendai nbutton" @click="onClickRange(2)">
            明治時代～1950年代
          </button>
        </div>
        <div class="menu" style="margin-top: 39px">
          <button class="nendai nbutton" @click="onClickRange(3)">
            1960年代～1970年代
          </button>
          <button class="nendai nbutton" @click="onClickRange(4)">
            1980年代～1990年代
          </button>
          <button class="nendai nbutton" @click="onClickRange(5)">
            2000年代以降
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixins/mixin'
export default {
  name: 'ArchiveIndex',
  mixins: [mixin],
  data: function () {
    return {
      iframeUrl: '',
      tamaImList: [],
      stories: []
    }
  },
  async created() {
    // console.log("created");
    // this.tamaImList = await this.getdata('/data/map_tama_im.json')
    // console.log('this.tamaImList', this.tamaImList)
    this.stories = await this.$store.getters.stories
    // console.log(this.stories.length);
  },
  methods: {
    onClickImageMap(index) {
      console.log(index)
      const imageMaps = this.$store.getters['imageMaps']
      console.log(imageMaps)
      const imageMap = imageMaps[index]
      const query = { keywords: imageMap.keywords }
      this.$store.dispatch('setFromForm', false)
      this.$emit('find', query, imageMap.name)
      // this.$emit('find', "")
    },
    onClickRange(index) {
      console.log(`onClickRange=${index}`)
      const ages = this.$store.getters['ages']
      console.log('ages', ages)
      console.log(ages[index])
      const age = ages[index]
      const query = { f5: `${age.range[0]}～${age.range[1]}`, f5_opt: 'range' }
      this.$emit('find', query, age.name)
      // console.log(this.$store.getters["ages"][index]);
    },
    openTamashiDA() {
      // this.iframeUrl =
      //   'https://www.youtube.com/channel/UC6W5T3tm7JSJde7v_EB50bQ'
      //       let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
      // width=1000,height=600,left=100,top=100`
      //       window.open(this.iframeUrl, '_blank', params)
    }
  }
}
</script>

<style>
.lbutton {
  height: 90px;
  width: 580px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 30px;
  border: none;
  outline: none;
  background-color: white;
}

.sonota {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 114px;
  height: 30px;
  background-color: white;
  background-image: url('../../../public/img/btn-sonota.png');
  background-repeat: no-repeat;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border: none;
  outline: none;
}

.contents > button {
  height: 248px;
  width: 38px;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

.contents > button > img {
  margin-bottom: 40px;
}

.kuwashiku {
  background-color: var(--theme-green-color);
}
.nbutton {
  height: 90px;
  width: 365px;
  border-radius: 20px;
  color: white;
  font-weight: bold;
  font-size: 24px;
  border: none;
  outline: none;
}

.nendai {
  background-color: var(--theme-blue-color);
}

#archive-index {
  display: flex;
  flex-direction: column;
}
#archive-index > .header {
  height: 190px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#archive-index .menu {
  display: flex;
  justify-content: space-between;
}

.map-tama {
  position: relative;
}
.map-tama .base {
  /* position: absolute;
  top: 0;
  left: 0; */
  display: block;
  height: 100%;
}
.map-tama .img-title {
  position: absolute;
  pointer-events: none; /* 追加 */
  top: 34px;
  left: -15px;
}

.map-tama .title {
  position: absolute;
  pointer-events: none; /* 追加 */
  top: 0;
  left: 0;
  font-size: 24px;
  font-weight: bold;
  color: var(--line-color);
}

.map-newtown {
  position: relative;
  margin-left: -15px;
}
.map-newtown .title {
  position: absolute;
  pointer-events: none; /* 追加 */
  top: 0;
  left: 0;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  color: var(--line-color);
}

.story {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.story > .header {
  font-size: 30px;
  font-weight: bold;
  color: var(--line-color);
}

.story > .contents {
  margin-top: 60px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.story > .contents ul {
  display: flex;
  /* justify-content: space-between; */
  /* width: 1164px; */
  overflow: auto;
  padding: 0;
  margin: 0;
}
.story > .contents ul li {
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 248px;
  margin: 0 20px;
}
.story > .contents > .list > .content > img {
  border-radius: 20px;
}
.story > .contents .content > .title {
  margin-top: 23px;
  font-size: 24px;
}

.story > .contents ul li:first-child {
  margin-left: 0;
}

.story > .contents ul li:last-child {
  margin-right: 0;
}

.iframe {
  position: absolute;
  top: 100px;
  left: 100px;
  width: 640px;
  height: 480px;
}
.iframe iframe {
  width: 100%;
  height: 100%;
}
</style>
