<template>
  <div id="resource-map">
    <div class="header">
      <img class="title" src="/img/title-gallary.png" alt="" />
    </div>
    <div class="mt17 mb107 map-containner">
      <GMapMap
        ref="mapref"
        :center="center"
        :zoom="zoom"
        style="width: 100%; height: calc(100vh - 124px - 50px)"
        :options="options"
        mapTypeId="roadmap"
        @click="onClickMap"
      >
        <GMapCluster>
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            :icon="{
              url: markerUrl(selectedMapIndex),
              labelOrigin: { x: 11, y: 11 }
            }"
            :label="{
              text: String(m.numData), //ラベル文字
              color: 'white', //ラベル文字の色
              fontFamily: 'sans-serif', //フォント
              fontWeight: 'bold', //フォントの太さ
              fontSize: '14px' //フォントのサイズ
            }"
            @click="onClickMarker(index)"
          >
            <!-- <GMapInfoWindow>
              <div>I am in info window <MyComponent /></div>
            </GMapInfoWindow> -->
          </GMapMarker>
        </GMapCluster>
      </GMapMap>
      <div class="menu">
        <div class="item header">テーマで探す</div>
        <div
          class="item"
          v-for="(item, index) in maps"
          :key="index"
          @click="preInitMap(index)"
        >
          <img :src="markerUrl(index)" alt="" class="ml13 mr8" />
          <div class="label">{{ item.title }}</div>
        </div>
      </div>
      <ResultLearge
        class="info"
        v-show="selectedObj != null"
        :data="selectedObj"
        @close.stop="selectedObj = null"
      />
      <img
        src="/img/close-btn.png"
        alt=""
        srcset=""
        class="close-btn"
        v-show="selectedObj != null"
      />
      <div
        class="info-navi"
        v-show="selectedObj != null && currentDataLength > 1"
      >
        <button
          @click="onPrevData"
          class="prev"
          :disabled="currentDataIndex == 0"
        ></button>
        <button
          @click="onNextData"
          class="next"
          :disabled="currentDataIndex == currentDataLength - 1"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import mixin from '../../mixins/mixin'
import ResultLearge from '../../components/ResultLearge.vue'
import { ref, onMounted } from 'vue'
export default {
  name: 'ResourceMap',
  components: { ResultLearge },
  mixins: [mixin],
  setup() {
    console.log('setup')
    const mapref = ref(null)
    onMounted(() => {
      // the DOM element will be assigned to the ref after initial render
      console.log('+++++')
      console.log(mapref.value) // <div/>
    })
    return {
      mapref
    }
  },
  data() {
    return {
      currentDataIndex: 0,
      selectedMarkerIndex: 0,
      selectedMapIndex: 0,
      selectedObj: null,
      zoom: 7,
      maps: [],
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          {
            stylers: [
              {
                hue: '#2c3e50'
              },
              {
                saturation: 250
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                lightness: 50
              },
              {
                visibility: 'simplified'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          }
        ]
      },
      center: { lat: 51.093048, lng: 6.84212 },
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212
          }
        } // Along list of clusters
      ]
    }
  },
  computed: {
    iconUrl() {
      if (this.currentMap) {
        return `/img/${this.currentMap.pointType}.png`
      } else {
        return `/img/point1.png`
      }
    },
    currentMap() {
      return this.maps[this.selectedMapIndex]
    },
    currentDataLength() {
      try {
        if (_.get(this.currentMap, 'locations')) {
          return this.currentMap.locations[this.selectedMarkerIndex].data.length
        } else {
          return 0
        }
      } catch (error) {
        console.log(error)
        return 0
      }
    }
  },
  created: function () {
    console.log('created')
  },
  mounted: async function () {
    console.log('mounted')
    console.log(this.center)
    try {
      // const res = await this.getdata('/data/map.json')
      this.maps = this.$store.getters['maps']
      this.initMap(0)
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    onPrevData() {
      this.currentDataIndex--
      if (this.currentDataIndex < 0) {
        this.currentDataIndex = 0
      }
      this.selectedObj =
        this.currentMap.locations[this.selectedMarkerIndex].data[
          this.currentDataIndex
        ]
    },
    onNextData() {
      this.currentDataIndex++
      if (
        this.currentDataIndex >
        this.currentMap.locations[this.selectedMarkerIndex].data.length - 1
      ) {
        this.currentDataIndex =
          this.currentMap.locations[this.selectedMarkerIndex].data.length - 1
      }
      this.selectedObj =
        this.currentMap.locations[this.selectedMarkerIndex].data[
          this.currentDataIndex
        ]
    },
    async preInitMap(index) {
      await this.resetMap()
      await this.$nextTick()
      await this.initMap(index)
    },
    onCenterChanged(e) {
      console.log('onDragEnd', e)
      // const lat = e.lat()
      // const lng = e.lng()
      // console.log(lat,lng);
      // this.center = { lat, lng }
    },
    onClickMap() {
      console.log('onClickMap', this.selectedMarkerIndex)
      this.selectedObj = null
    },
    clickEventFunc() {
      console.log('OKOKO')
    },
    markerUrl(index) {
      if (this.maps[index]) {
        return `/img/${this.maps[index].pointType}.png`
      } else {
        return `/img/point1.png`
      }
    },
    resetMap() {
      const lat = 0
      const lng = 0
      console.log(lat, lng)
      this.center = { lat, lng }
      this.zoom = 1
    },
    initMap(index) {
      this.selectedObj = null
      this.selectedMapIndex = index
      console.log(`initMap=${index}`)
      const map = this.maps[index]
      console.log(map)
      this.center.lat = map.lat
      this.center.lng = map.lng
      console.log('this.center', this.center)
      this.zoom = map.zoom
      let markers = []
      map.locations.forEach((location) => {
        console.log('location', location.data)
        markers.push({
          position: { lat: location.lat, lng: location.lng },
          numData: location.data.length
        })
      })
      this.markers = markers
    },
    onClickMarker(index) {
      console.log('onClickMarker', index)
      this.selectedMarkerIndex = index
      this.currentDataIndex = 0
      if (index < 0) {
        this.selectedObj = null
      } else {
        console.log('loc', this.currentMap.locations[index])
        this.selectedObj =
          this.currentMap.locations[index].data[this.currentDataIndex]
        // window.open(this.currentMap.locations[index].url, null ,'top=100,left=100,width=930,height=460,location=false');
      }
      // console.log('selectedUrl', this.selectedUrl)
    }
  }
}
</script>

<style scoped>
#resource-map {
  display: flex;
  flex-direction: column;
}
#resource-map > .header {
  height: 124px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#resource-map .menu {
  position: absolute;
  width: 270px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: 0;
  left: 0;
}

#resource-map .menu .item {
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: solid 1px black;
  font-weight: bold;
  font-size: 18px;
}
#resource-map .menu .item.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #82992b;
  color: white;
}

#resource-map .map-containner {
  height: calc(100vh - 124px - 50px);
  position: relative;
}
.info {
  position: absolute;
  left: 230px;
  bottom: 120px;
  /* z-index: 1; */
}
.info-navi {
  position: absolute;
  left: 540px;
  bottom: 20px;
  /* z-index: 1; */
}

.info-navi button {
  width: 153px;
  height: 76px;
  margin: 0 10px;
}

.info-navi button:disabled {
  opacity: 0.5;
}

.info-navi .prev {
  background-image: url(@/assets/prevData.png);
}
.info-navi .next {
  background-image: url(@/assets/nextData.png);
}

.close-btn {
  position: absolute;
  bottom: 524px;
  left: 1219px;
  pointer-events: none;
}
</style>

<style>
#resource-map .vue-map-container {
  height: 100%;
}
</style>
